import _ from "lodash";
import React, { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from '../../assets/search_icon.svg'
interface ISearch {
  onSearch: (s: string) => void;
  searchQuery?: string;
  className?: string;
  inputClassName?: string;
}

const SimpleSearch = ({ onSearch, searchQuery, className, inputClassName }: ISearch) => {
  const { t } = useTranslation();

  const _onSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onSearch(e.target.value);
    },
    [onSearch]
  );

  const clearSearch = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onSearch("");
    },
    [onSearch]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const form = e.target as HTMLFormElement;
      const formData = new FormData(form);
      const formJson = Object.fromEntries(formData.entries());
      onSearch(formJson.query as string);
    },
    [onSearch]
  );

  return (
    <div className={`relative ${className}`}>
      <form method="post" onSubmit={onSubmit}>
        <input
          name="query"
          className={`simple-search-input bg-transparent ${inputClassName}`}
          type="text"
          onChange={_onSearch}
          value={searchQuery}
          placeholder={t("actions.search_placeholder")}
        />

        <button type="submit" className="btn-search">
          <svg className="w-full h-full">
            <use xlinkHref={`${SearchIcon}#search`} />
          </svg>
        </button>
        {!_.isEmpty(searchQuery) && <button className="btn-clear-search" onClick={clearSearch} />}
      </form>
    </div>
  );
};

export default SimpleSearch;
