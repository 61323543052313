import React from "react";
import { Link } from "react-router-dom";
import { guidelineShape } from "../../lib/data_shapes";

interface IGuidelineHeader {
  guideline: {
    "@id": string;
    title: string;
    source: string;
  };
}

const GuidelineHeader = ({ guideline }: IGuidelineHeader) => {
  return (
    <div className="bg-white p-5 rounded-t-lg">
      <Link to={`/guideline/${guideline["@id"]}`}>
        <h1 className="text-lg roboto-condensed font-semibold text-red-200 hover:underline">{guideline.title}</h1>
      </Link>
    </div>
  );
};

GuidelineHeader.propTypes = {
  guideline: guidelineShape,
};

export default GuidelineHeader;
