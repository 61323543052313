export const contactMail = "GRADEadm@mcmaster.ca";
export const emlMainPageLink = "https://list.essentialmeds.org/";
export const evidenceprimeLink = "https://evidenceprime.com";
export const glossaryLink = "https://gdt.gradepro.org/app/handbook/handbook.html#h.buaodtl66dyx";
export const loveMainPageLink = "https://iloveevidence.com/";
export const niphLink = "https://www.nornesk.no/forskningskart/NIPH_mainMap.html";
export const surveyLink = "https://www.surveymonkey.com/r/8Y5QB6Z";
export const twitterLink = "https://twitter.com/CochraneCanada";
export const mcMasterLink = "https://www.mcmaster.ca/";
export const cochraneCanadaLink = "https://canada.cochrane.org/";
export const ginLink = "https://g-i-n.net/";
export const linkToPrivacyPolicy = "https://gradepro.org/privacy.html/";
export const webinarLink = "https://connect.g-i-n.net/events/61fbe9caadaa54000740d498/description";
export const linkedIn = "https://linkedin.com/company/cochrane-canada";
export const facebook = "https://facebook.com/CochraneCanada";
export const supportLink = "support@evidenceprime.com";
export const showExpirationBanner = false;
export const instagramLink = "https://www.instagram.com/ecovid19recmap/";
export const getItGlossaryLink = "https://getitglossary.org/";
