import _ from "lodash";
import React from "react";
import ReactTooltip from "react-tooltip";
import { useResizeContainer } from "../../lib/custom_hooks";
import { TRecommendationListItem } from "../../lib/types";
import RecommendationsList from "./RecommendationsList";

interface IRecommendationGroupList {
  recommendations: TRecommendationListItem[];
}

const RecommendationGroupsList = ({ recommendations }: IRecommendationGroupList) => {
  const containerId = "resizable-container";
  useResizeContainer(containerId);

  return (
    <div className="mx-0 md:mx-2 pt-0">
      <div id={containerId} className="recommendation-list-container max-w-screen-lg mt-0 pt-0">
        {_.isEmpty(recommendations) ? (
          <div className="no-results" />
        ) : (
          <RecommendationsList recommendations={recommendations} />
        )}
      </div>
      <ReactTooltip
        className="tooltip-container"
        type="dark"
        effect="solid"
        backgroundColor="#031B49"
        clickable
        delayHide={300}
      />
    </div>
  );
};

export default RecommendationGroupsList;
