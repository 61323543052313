import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import interventionsBarThumbnail from "../../assets/interventions-bar.svg";
import populationsBarThumbnail from "../../assets/populations-bar.svg";
import recommendationBoxThumbnail from "../../assets/recommendation-box.png";
import recommendationCounterThumbnail from "../../assets/recommendation-counter.svg";
import { useRecommendationsFetch } from "../../lib/api";
import { useResizeContainer, useSearchQuery } from "../../lib/custom_hooks";
import { generateColorMap } from "../../lib/helpers";
// eslint-disable-next-line no-unused-vars
import Sidebar from "../Sidebar";
import FilterTags from "../common/FilterTags";
// eslint-disable-next-line no-unused-vars
import InstructionModal from "../common/InstructionModal";
import Search from "../common/Search";
// eslint-disable-next-line no-unused-vars
import SearchInstructions from "../common/SearchInstructions";
import RecommendationsMapTable from "../recommendationMap/RecommendationsMapTable";
import { Link } from "react-router-dom";
import ExternalLink from "../common/ExternalLink";

// TODO use gradient array
const gradientStart = "#99CE86";
const gradientMiddle = "#F0C343";
const gradientEnd = "#FF5D73";

const THUMBNAILS = {
  interventionsBar: interventionsBarThumbnail,
  populationsBar: populationsBarThumbnail,
  recommendationBox: recommendationBoxThumbnail,
  recommendationCounter: recommendationCounterThumbnail,
};

const InstructionSection = ({ sectionId }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row mb-3 last:mb-0">
      <div
        className="flex flex-shrink-0 p-2 text-center bg-gray-100 w-128 mr-3"
        style={{ width: "200px" }}
      >
        <img className="mx-auto" src={THUMBNAILS[sectionId]} alt="" />
      </div>
      <div>
        <div className="font-semibold text-red-200 mb-2">{t(`labels.${sectionId}`)}</div>
        <div>{t(`generalInstructions.${sectionId}`)}</div>
      </div>
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const GridInstructions = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-5">{t("generalInstructions.description")}</div>
      <InstructionSection sectionId="interventionsBar" />
      <InstructionSection sectionId="populationsBar" />
      <InstructionSection sectionId="recommendationCounter" />
      <InstructionSection sectionId="recommendationBox" />
    </>
  );
};

const GridHeader = ({ onChangeFilter, currentFilters, onRemoveFilter }) => {
  const { t } = useTranslation();
  const initialSearchQuery = useSearchQuery();

  return (
    <div id="search-and-tags-container" className="grid-page-header w-full bg-white shadow">
      <div className="flex flex-col md:flex-row">
        <Search
          className="inline-block ml-3 mr-6 mt-1 mb-2"
          searchQuery={initialSearchQuery}
          onSearch={(query) => onChangeFilter("searchQuery", query)}
        />
        <Link className="my-auto mx-3 text-red-200" to="/glossary">
          {t("labels.glossary")}
        </Link>
        <ExternalLink className="my-auto mx-3 text-red-200" url={t("links.guidelines")}>
          {t("labels.good_practises")}
        </ExternalLink>
      </div>
      <FilterTags currentFilters={currentFilters} onRemove={onRemoveFilter} />
    </div>
  );
};

const Grid = ({
  isLoading,
  currentIntentSelected,
  onChangeIntentSelected,
  data,
  onChangeFilter,
  currentFilters,
  // eslint-disable-next-line no-unused-vars
  onClearFilters,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [heatMap, setHeatMap] = useState(false);

  const colorsCount = data.maxCount || 10;
  // TODO use gradient array
  const colorMap = useMemo(
    () => generateColorMap(gradientStart, gradientMiddle, gradientEnd, colorsCount),
    [colorsCount]
  );

  const onRemoveFilter = useCallback(
    (filterKey) => {
      onChangeFilter(filterKey, "any");
    },
    [onChangeFilter]
  );

  useResizeContainer("rmt-table", [currentFilters]);

  return (
    <>
      <GridHeader
        onChangeFilter={onChangeFilter}
        currentFilters={_.omit(currentFilters, ["intent"])}
        onRemoveFilter={onRemoveFilter}
      />
      <RecommendationsMapTable
        currentIntentSelected={currentIntentSelected}
        onChangeIntentSelected={onChangeIntentSelected}
        colorMap={colorMap}
        isLoading={isLoading}
        displayMode={heatMap ? "heatMap" : "normal"}
        grid={data}
      />
      <Sidebar
        colorsCount={colorsCount}
        currentFilters={currentFilters}
        expandable
        filters={data.filterValues}
        heatMap={heatMap}
        isLoading={isLoading}
        gridSidebar
        onChangeFilter={onChangeFilter}
        onClearFilters={onClearFilters}
        setHeatMap={setHeatMap}
      />
    </>
  );
};

const GridPage = () => {
  const { t } = useTranslation();

  const {
    currentFilters,
    currentIntentSelected,
    data,
    isError,
    isLoading,
    onChangeFilter,
    onChangeIntentSelected,
    onClearFilters,
  } = useRecommendationsFetch("grid");

  return (
    <div className="bg-gray-100 page-content grid-container relative font-transat">
      {isError && <div>{t("errors.fetching_failed")}</div>}
      <Grid
        currentFilters={currentFilters}
        currentIntentSelected={currentIntentSelected}
        data={data}
        isLoading={isLoading}
        onChangeFilter={onChangeFilter}
        onChangeIntentSelected={onChangeIntentSelected}
        onClearFilters={onClearFilters}
      />
    </div>
  );
};

export default GridPage;
