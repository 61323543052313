import _ from "lodash";
import React, { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import InstructionModal from "./InstructionModal";

const SCORES: string[] = ["scope_and_purpose", "rigor_of_development", "editorial_process"];

interface IScores {
  agreeScore: {
    editorialProcess: number | undefined;
    rigorOfDevelopment: number | undefined;
    scopeAndPurpose: number | undefined;
    scorePassed: boolean | undefined;
    [key: string]: number | boolean | undefined;
  };
}

const Scores: FC<IScores> = ({ agreeScore }) => {
  const { t } = useTranslation();
  const hasAnyScores = _.some(SCORES, (scoreKey) => _.has(agreeScore, _.camelCase(scoreKey)));

  return hasAnyScores ? (
    <div className="bordered-info-box">
      <InstructionModal
        triggerLabel={
          <span className="font-semibold hover:underline cursor-pointer">
            {t("recommendation.agree_score")}
          </span>
        }
      >
        <h1 className="text-xl mb-6 font-semibold">{t("labels.agree_II")}</h1>
        <Trans i18nKey="detailsOfRecommendationPage.agree_II">
          <p className="mb-2">
            The credibility of a guideline is assessed using the AGREE II instrument.
          </p>
          <p className="mb-2">
            We present on this page the scores on three of the six AGREE II domains (Scope and
            Purpose, Rigour of Development and Editorial Independence). We have chosen these three
            domains as the core domains for methodological rigour and transparency of the guideline.
          </p>
          <p className="mb-2">
            A domain contains multiple items. For example, ‘Systematic methods were used to search
            for evidence’ is one of the items of the domain ‘Rigour of Development’. Each item is
            rated on a 7-point scale (1 = strongly disagree to 7 = strongly agree), by two raters
            independently. Domain scores are calculated by summing up all the scores of the
            individual items in a domain and by scaling the total as a percentage of the maximum
            possible score for that domain. This means that domain scores can range between 0% and
            100%. The higher the score, the more criteria for that specific domain are fulfilled.
          </p>
        </Trans>
      </InstructionModal>
      <div>
        {_.map(SCORES, (scoreKey: string) => {
          const value: number | boolean | undefined = agreeScore[_.camelCase(scoreKey)];
          return _.isNil(value) ? null : (
            <div className="flex justify-between" key={scoreKey}>
              <div>{t(`recommendation.score.${scoreKey}`)}:</div>
              <div>{value}%</div>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
};

export default Scores;
