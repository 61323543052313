import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  contactMail,
  evidenceprimeLink,
  facebook,
  linkedIn,
  twitterLink,
} from "../../lib/constants";
import ExternalLink from "../common/ExternalLink";
import TwitterImage from "../../assets/twitter_canpcc.svg"
import FacebookImage from "../../assets/facebook_canpcc.svg"
import LinkedInImage from "../../assets/linkedin_canpcc.svg"


const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-red-200 py-5">
      <div className="mx-auto container text-white">
        <div className="footer">
          <div className="footer__info px-3">
            <ExternalLink url="https://canpcc.ca/" className="text-white text-2xl footer__info__title ">{t('footer.title')}</ExternalLink>
            <div className="footer__socials social-link--footer my-4">
              {!_.isEmpty(twitterLink) && (
                <div>
                  <a
                    className="social-link social-link--footer"
                    href={twitterLink}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    <svg className="twitter-link">
                      <use xlinkHref={`${TwitterImage}#twitter`}/>
                    </svg>
                  </a>
                </div>
              )}
              {!_.isEmpty(facebook) && (
                <div>
                  <a
                    className="social-link social-link--footer"
                    href={facebook}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    <svg className="facebook-link">
                      <use xlinkHref={`${FacebookImage}#facebook`}/>
                    </svg>
                  </a>
                </div>
              )}
              {!_.isEmpty(linkedIn) && (
                <div>
                  <a
                    className="social-link social-link--footer"
                    href={linkedIn}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    <svg className="linkedin-link">
                      <use xlinkHref={`${LinkedInImage}#linkedin`}/>
                    </svg>
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="footer--texts">
            <div className="mx-auto px-3">
              <p className="mb-3">{t('footer.information')}</p>
              <p className="mb-3">{t('footer.information_french')}</p>
            </div>
            <div className="mx-auto px-3">
              <h3 className="bold mb-3 text-center text-xl">{t('labels.contact')}</h3>
              <p>{t('labels.email_short')} : <a className="underline" href={`mailto:${contactMail}`} target="_blank" rel="noopener noreferrer">{contactMail}</a></p>
            </div>
          </div>

        </div>

        <div className="mx-auto mt-5 text-center">
          <a
            className="block mx-auto text-white font-semibold"
            href={evidenceprimeLink}
            target="blank"
            rel="noopener noreferrer"
          >
            {t("labels.crafted_by_evidenceprime")}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
