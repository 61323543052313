import React, { FC } from "react";
import _ from "lodash";
import Box from "../common/Box";
import { useTranslation } from "react-i18next";

interface IGlossaryItem {
  title: string;
  value: any;
}

const GlossaryItem: FC<IGlossaryItem> = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <div className="mb-6 glossary-item last:mb-0">
      <div className="font-bold mb-1 text-3xl">{title}</div>
      {_.isString(value) ? (
        <div className="mb-12" dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <>
          <div className="tailwind-hidden md:block">
            <table className="glossary">
              <thead>
                <tr>
                  <th>{t("labels.implications_for")}</th>
                  <th>{t("labels.strong_recommendation")}</th>
                  <th>{t("labels.conditional_recommendation")}</th>
                </tr>
              </thead>
              <tbody>
                {_.map(value, (v, key) => (
                  <tr key={key}>
                    <td className="text-center">{v.title}</td>
                    <td>{v.strong_recommendation}</td>
                    <td>{v.conditional_recommendation}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="block md:hidden">
            <div className="font-semibold text-red-200 my-3">{t("labels.implications_for")}</div>
            {_.map(value, (v, key) => {
              return (
                <div key={key} className="border-b border-red-200 pb-4 mt-4">
                  <div className="text-xl font-semibold my-3">{v.title}</div>
                  <div className="text-red-200 underline">
                    {t("labels.strong_recommendation")}
                  </div>
                  <div className="mb-3">{v.strong_recommendation}</div>
                  <div className="text-red-200 underline">
                    {t("labels.conditional_recommendation")}
                  </div>
                  <div>{v.conditional_recommendation}</div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const Glossary = () => {
  const { t } = useTranslation();

  const glossary = t("glossary", { returnObjects: true });

  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <div className="w-full flex flex-row align-center justify-between mb-5 mr-5">
        <h1 className="pl-6 pt-2 text-5xl font-normal roboto-condensed">{t(`labels.glossary`)}</h1>
      </div>
      <Box className="p-5 bg-white mb-6">
        {_.map(glossary, (glossaryItem: { title: string; value: any }, key: string) =>
          key === "gradingSystemTable" ? (
            <GradingSystem key={key} title={glossaryItem.title} />
          ) : (
            <GlossaryItem key={key} title={glossaryItem.title} value={glossaryItem.value} />
          )
        )}
      </Box>
    </div>
  );
};

const GRADING_SYSTEM_TABLE = {
  thead: [["strength", "explanation", "empty", "strength_of_recommendation", "symbol"]],
  tbody: [
    ["a", "strong_recommendation_for", "empty", "strong_for_an_intervention", "&#8593;&#8593;"],
    ["b", "moderate_recommendation_for", "empty", "conditional_for_an_intervention", "&#8593;?"],
    ["c", "optional_recommendation", "empty", "conditional_for_either", "??"],
    [
      "b",
      "moderate_recommendation_against",
      "empty",
      "conditional_against_an_intervention",
      "&#8595;?",
    ],
    [
      "a",
      "strong_recommendation_against",
      "empty",
      "strong_against_an_intervention",
      "&#8595;&#8595;",
    ],
  ],
};

const GRADING_SYSTEM_TABLE_MOBILE = {
  tbody: [
    [
      { strength: "a", explanation: "strong_recommendation_for" },
      { strength_of_recommendation: "strong_for_an_intervention", symbol: "&#8593;&#8593;" },
    ] as TableRow,
    [
      { strength: "b", explanation: "moderate_recommendation_for" },
      { strength_of_recommendation: "conditional_for_an_intervention", symbol: "&#8593;?" },
    ] as TableRow,
    [
      { strength: "c", explanation: "optional_recommendation" },
      { strength_of_recommendation: "conditional_for_either", symbol: "??" },
    ] as TableRow,
    [
      { strength: "b", explanation: "moderate_recommendation_against" },
      { strength_of_recommendation: "conditional_against_an_intervention", symbol: "&#8595;?" },
    ] as TableRow,
    [
      { strength: "b", explanation: "strong_recommendation_against" },
      { strength_of_recommendation: "strong_against_an_intervention", symbol: "&#8595;&#8595;" },
    ] as TableRow,
  ],
};

const GRADING_SYSTEM_TABLE_MOBILE_2 = {
  tbody: [
    [
      { loe: "I", explanation: "one_or_more_randomized" },
      { coe: "high", symbol: "circles_high", explanation: "very_confident" },
    ] as TableRow,
    [
      { loe: "IIa", explanation: "other_randomized" },
      { coe: "moderate", symbol: "circles_moderate", explanation: "moderately_confident" },
    ] as TableRow,
    [
      { loe: "IIb", explanation: "one_or_more_well_designed" },
      { coe: "low", symbol: "circles_low", explanation: "limited_confidence" },
    ] as TableRow,
    [
      { loe: "dash", explanation: "dashes" },
      { coe: "very_low", symbol: "circles_very_low", explanation: "very_little_confidence" },
    ] as TableRow,
    [
      { loe: "III", explanation: "expert_opinion" },
      { coe: "no_search_evidence", symbol: "ir/gps", explanation: "informal_gps" },
    ] as TableRow,
  ],
};

const GRADING_SYSTEM_TABLE_2 = {
  thead: [["loe", "explanation", "empty", "coe", "symbol", "explanation"]],
  tbody: [
    ["I", "one_or_more_randomized", "empty", "high", "circles_high", "very_confident"],
    ["IIa", "other_randomized", "empty", "moderate", "circles_moderate", "moderately_confident"],
    ["IIb", "one_or_more_well_designed", "empty", "low", "circles_low", "limited_confidence"],
    ["dash", "dashes", "empty", "very_low", "circles_very_low", "very_little_confidence"],
    ["III", "expert_opinion", "empty", "no_search_evidence", "ir/gps", "informal_gps"],
  ],
};

const GradingSystem = ({ title }: { title: string }) => {
  const { t } = useTranslation("");

  return (
    <div className="mb-6 glossary-item last:mb-0">
      <div className="font-semibold mb-1 text-2xl">{title}</div>
      <div className="mt-12 flex flex-row">
        <div className="font-semibold flex-grow flex-shrink-0 text-red-200 mb-1 text-center">
          {t("gradingSystemTable.nih")}
        </div>
        <div className="font-semibold flex-grow flex-shrink-0 text-red-200 mb-1 text-center">
          {t("gradingSystemTable.grade")}
        </div>
      </div>
      <div className="tailwind-hidden md:block">
        <GradingTable table={GRADING_SYSTEM_TABLE} />
      </div>
      <div className="block md:hidden">
        <GradingTableMobile table={GRADING_SYSTEM_TABLE_MOBILE} />
      </div>
      <div className="mt-12 flex flex-row">
        <div className="font-semibold flex-grow flex-shrink-0 text-red-200 mb-1 text-center">
          {t("gradingSystemTable.nih")}
        </div>
        <div className="font-semibold flex-grow flex-shrink-0 text-red-200 mb-1 text-center">
          {t("gradingSystemTable.grade")}
        </div>
      </div>
      <div className="tailwind-hidden md:block">
        <GradingTable table={GRADING_SYSTEM_TABLE_2} />
      </div>
      <div className="block md:hidden">
        <GradingTableMobile table={GRADING_SYSTEM_TABLE_MOBILE_2} />
      </div>
    </div>
  );
};

type GradingSystemTable = {
  thead: string[][];
  tbody: string[][];
};

const GradingTable = ({ table }: { table: GradingSystemTable }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-grow mx-2 w-full">
      <table className="w-full glossary grading-table">
        <thead>
          {_.map(table.thead, (row, idx) => (
            <tr key={idx}>
              {_.map(row, (cell, indx) => (
                <th className={cell} key={`${indx}-${cell}`}>
                  {cell !== "empty" ? t(`gradingSystemTable.${cell}`) : ""}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {_.map(table.tbody, (row, idx) => (
            <tr key={idx}>
              {_.map(row, (cell, indx) => (
                <td key={`${indx}-${cell}`} className={cell}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: cell !== "empty" ? t(`gradingSystemTable.${cell}`) : "",
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

type TableCell = {
  [key: string]: string;
};
type TableRow = TableCell[];

type GradingSystemTableMobile = {
  tbody: TableRow[];
};

const GradingTableMobile = ({ table }: { table: GradingSystemTableMobile }) => {
  const { t } = useTranslation();
  return (
    <table className="w-full glossary">
      <tbody>
        {_.map(table.tbody, (tr, idx) => (
          <tr key={idx}>
            {_.map(tr, (cell) => (
              <td>
                {_.map(cell, (value, key) => (
                  <div>
                    <div className="font-semibold">{t(`gradingSystemTable.${key}`)}</div>
                    <div dangerouslySetInnerHTML={{ __html: t(`gradingSystemTable.${value}`) }} />
                  </div>
                ))}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Glossary;
