import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { referenceShape } from "../../lib/data_shapes";

type TReference = {
  "@id": number;
  value: string;
};

interface IReferences {
  references: TReference[];
}

const References = ({ references }: IReferences) => {
  const { t } = useTranslation();
  return (
    <div className="p-3">
      <h3 className="font-semibold text-red-200">{t("labels.references")}</h3>
      {map(references, (r) => (
        <div key={r["@id"]}>
          <span className="font-semibold">{r["@id"]}</span>
          {": "}
          <span className="text-sm">{r.value}</span>
        </div>
      ))}
    </div>
  );
};

References.propTypes = {
  references: PropTypes.arrayOf(referenceShape).isRequired,
};

export default References;
