import _ from "lodash";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetApi } from "../../lib/api";
import LargeSpinner from "../common/LargeSpinner";

export default function OurPageInNumbers(): ReactElement {
  const { t } = useTranslation();
  // @ts-ignore
  const [{ data = [], isLoading }] = useGetApi(`/stats`);

  return isLoading && _.isEmpty(data) ? (
    <LargeSpinner />
  ) : (
    <div className="our-page-in-numbers py-12">
      <h1 className="text-3xl text-center roboto-condensed mb-6">
        {t("statistics.ourProjectInNumbers")}
      </h1>
      <div className="flex justify-center">
          <Bubble
            total={data.gps}
            dataKey="gps"
          />
          <Bubble
            total={data.recommendations}
            dataKey="recommendations"
          />
      </div>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://forms.office.com/Pages/ResponsePage.aspx?id=B2M3RCm0rUKMJSjNSW9Hcu-N6euSBxxCkS2Y5ZJS3tdUODdJTzZKTUY0RExZTE85QjlXQkxWSVJJUS4u"
        className="block roboto-condensed text-3xl link-red text-center mt-6 mx-auto"
      >
        {t("statistics.stayTuned")}
      </a>
    </div>
  );
}

interface IBubble {
  total: number;
  dataKey: string;
}

const Bubble = ({ total, dataKey}: IBubble) => {
  const { t } = useTranslation();
  const bubbleKey = useMemo(() => {
    switch (dataKey) {
      case "gps":
        return "gps";
      case "recommendations":
        return "formal";
    }
  }, [dataKey]);

  return (
    <div className="bubble-container">
      <div className="bubble">
      <h1>
        {total}
        <span className="roboto-condensed">{t(`labels.recommendationHeader.${bubbleKey}`)}</span>
      </h1>
      </div>
      
    </div>
  );
};
