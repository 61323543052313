import React from "react";

interface IExternalLink {
  children?: React.ReactNode;
  url: string;
  className?: string;
  target?: string;
}

const ExternalLink = ({ children, url, className, target }: IExternalLink) => (
  <a
    className={className ?? "py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3 block"}
    href={url}
    rel="noopener noreferrer"
    target={target ?? "_blank"}
  >
    {children || url}
  </a>
);

export default ExternalLink;
