import _ from "lodash";
import React from "react";
import { useGetApi } from "../../lib/api";
import Box from "../common/Box";
import LargeSpinner from "../common/LargeSpinner";

const Experts = () => {
  // @ts-ignore
  const [{ data, isLoading }] = useGetApi(`/experts-bureau`);

  return (
    <div className="pb-6">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <Box className="p-5 bg-white mt-5">
          <div className="p-4">
            {isLoading ? (
              <LargeSpinner />
            ) : _.isEmpty(data) ? null : (
              <div>
                <h1 className={`pl-6 pt-2 text-2xl text-red-200 font-normal`}>{data[0].title}</h1>
                <div className="kb-content" dangerouslySetInnerHTML={{ __html: data[0].body }} />
              </div>
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Experts;
