import _, { isEmpty } from "lodash";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "../i18n";
import { axiosInstance as axios } from "./api_utils";
import { params2filters } from "./helpers";
import { isDebugMode, isTestEnv } from "./utils";

export const handleApiError = (error) => {
  if (isDebugMode() || isTestEnv()) console.warn(error);
  // if (!isDebugMode()) sendReportToSentry(error);
};

export const useGetApi = (initialUrl, initialData = {}) => {
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  params.set("lng", i18n.language || "en");
  const fullUrl = url.split("?")[0] + "?" + params.toString();

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const result = await axios.get(fullUrl);
        // I don't like this, but this is the only way that it works the way I want to. I want it to work like this
        // 1. progress is increasing to some point (e.g 45%) with interval
        // 2. then it stops, which actually means that data are loaded, and I can't do anything using state, I guess because of react batch update
        // 3. This part is for rendering full width of the progress bar just before it disappears.
        const fakeLoaderBar = document.getElementById("fake-loader__progress-bar");
        const fakeLoaderStatus = document.getElementById("fake-loader__progress-status");
        fakeLoaderBar && fakeLoaderBar.style.setProperty("width", "100%");
        if (fakeLoaderStatus) fakeLoaderStatus.innerHTML = "100%";
        setData(result.data);
      } catch (e) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [fullUrl]);
  return [{ data, isLoading, isError }, setUrl];
};

export const usePostApi = (url, initialBody, initialData = {}) => {
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);

  const [body, setBody] = useState(initialBody);

  useEffect(() => {
    if (!isEmpty(body)) {
      const fetchData = async () => {
        setIsError(false);
        setIsLoading(true);
        try {
          const result = await axios.post(url, body);
          setData(result.data);
        } catch (e) {
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
  }, [url, body]);
  return [{ data, isLoading, isError }, setBody];
};

export const sendRequestForAdolopment = (requestBody) => {
  return axios.post("/adolopment", requestBody).catch(handleApiError);
};

export const useRecommendationsFetch = (apiPrefix) => {
  const location = useLocation();
  const history = useHistory();
  const currentParams = useMemo(() => new URLSearchParams(location.search), [location]);
  const [currentFilters, setCurrentFilters] = useState(params2filters(currentParams));

  const [currentIntentSelected, setCurrentIntentSelected] = useState(
    currentParams.get("intent") || "all"
  );

  const [{ data, isLoading, isError }, setUrl] = useGetApi(
    `/${apiPrefix}?${currentParams.toString()}`
  );
  const onChangeIntentSelected = useCallback(
    (intent) => () => {
      const newIntent = intent === currentIntentSelected ? "all" : intent;
      intent === "all" || _.isEmpty("intent")
        ? currentParams.delete("intent")
        : currentParams.set("intent", intent);
      setCurrentIntentSelected(newIntent);
      const newUrl = `/${apiPrefix}?${currentParams.toString()}`;
      history.push(`?${currentParams.toString()}`);
      setUrl(newUrl);
    },
    [currentIntentSelected, apiPrefix, currentParams, history, setUrl]
  );

  const onChangeFilter = (key, value) => {
    value === "any" || _.isEmpty(value) ? currentParams.delete(key) : currentParams.set(key, value);
    const newUrl = `/${apiPrefix}?${currentParams.toString()}`;
    history.push(`?${currentParams.toString()}`);
    setCurrentFilters(params2filters(currentParams));
    setUrl(newUrl);
  };

  const onClearFilters = useCallback(() => {
    setUrl(`/${apiPrefix}?`);
    history.push(`/${apiPrefix}?`);
    setCurrentFilters({});
  }, [setUrl, history, apiPrefix]);

  return {
    currentFilters,
    currentIntentSelected,
    data,
    isError,
    isLoading,
    onChangeFilter,
    onChangeIntentSelected,
    onClearFilters,
  };
};
