import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import ExpandableCard from "../common/ExpandableCard";
import ExternalLink from "../common/ExternalLink";

type TPlr = {
  link: string;
  text: string;
};

export interface IPlainLanguageSummaryProps {
  plainLanguageSummaryDescription: string;
  plainLanguageSummaries: TPlr[];
  toggleRef: HTMLDivElement;
}

export default function PlainLanguageSummary({
  plainLanguageSummaries,
  plainLanguageSummaryDescription,
  toggleRef,
}: IPlainLanguageSummaryProps) {
  const { t } = useTranslation();

  return (
    <ExpandableCard
      title={t("labels.plainLanguageSummary")}
      tooltip={t("tooltips.plainLanguageSummary")}
      contentClassName="px-1"
      toggleRef={toggleRef}
    >
      <div className="mx-4">
        {!_.isEmpty(plainLanguageSummaryDescription) && (
          <div
            className="mb-4"
            dangerouslySetInnerHTML={{ __html: plainLanguageSummaryDescription }}
          />
        )}
        {_.map(plainLanguageSummaries, ({ text, link }, idx) => (
          <div key={idx}>
            <ExternalLink className="text-red-200 hover:underline" url={link}>
              {_.isEmpty(text) ? `${t("labels.goToPlainLanguageSummary")}` : text}
            </ExternalLink>
          </div>
        ))}
      </div>
    </ExpandableCard>
  );
}
