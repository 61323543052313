import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import { isValidLink } from "../../lib/utils";
import Box from "./Box";
import Card from "./Card";
import Certainty from "./Certainty";
import CustomTable from "./CustomTable";
import ExternalLink from "./ExternalLink";
import EmlLinks from "./EmlLinks";
import LoveLinks from "./LoveLinks";
import EquityLinks from "./EquityLinks";

const AGE_ORDER = ["infant", "child", "adolescent", "adult", "unspecified"];

const Ages = ({ ages }) => {
  return <div className="py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3 block">{ages}</div>;
};

const Populations = ({ populations }) =>
  _.map(populations, (population) => (
    <div key={population["@id"]}>{_.upperFirst(population.description)}</div>
  ));

const Interventions = ({ interventions }) =>
  _.map(interventions, (intervention) => (
    <div key={intervention["@id"]}>{_.upperFirst(intervention.name)}</div>
  ));

const mapIdsToValues = (ids, values) =>
  _.chain(ids)
    .map((id) => values.find((v) => v["@id"] === id["@id"]))
    .compact()
    .value();

const getAdditionalRows = (question, t) =>
  _.chain(["evidenceProfile", "etd", "evidenceSynthesis"])
    .map((key) => {
      if (!_.isEmpty(question[`${key}Reference`])) {
        return {
          label: t(`labels.${key}`),
          value: isValidLink(question[`${key}Reference`]) ? (
            <a
              className="external-link"
              href={question[`${key}Reference`]}
              target="_blank"
              rel="noreferrer noopener"
            >
              {!_.isEmpty(question[`${key}Pages`])
                ? t("labels.see_pages", { pages: question[`${key}Pages`] })
                : question[`${key}Reference`]}
            </a>
          ) : (
            question[`${key}Reference`]
          ),
        };
      }
      return null;
    })
    .compact()
    .value();

const QuestionData = ({
  commonStrengthAndCertainty,
  emlLinks,
  evidenceTableLink,
  interventions,
  loveLink,
  populations,
  question,
  progress,
}) => {
  const { t } = useTranslation();
  const questionPopulations = mapIdsToValues(question.population, populations);
  const questionInterventions = mapIdsToValues(question.intervention, interventions);
  const additionalRows = getAdditionalRows(question, t);
  const ages = _.chain(questionPopulations)
    .map("ages")
    .flatten()
    .compact()
    .uniq()
    .sort((age1, age2) => AGE_ORDER.indexOf(age1) - AGE_ORDER.indexOf(age2))
    .map((age) => t(`recommendation.ages.${age?.toLowerCase()}`))
    .join(", ")
    .value();

  const showEquityLinks = useMemo(() => _.some(progress, _.negate(_.isEmpty)), [progress]);

  return (
    <Card className={`question-data ${!commonStrengthAndCertainty ? "px-0" : ""} border border-red-200`}>
      <div className="flex flex-row">
        <CustomTable
          className="flex-grow"
          valueClassName="sm:px-0 sm:py-0"
          rows={[
            {
              label: t("recommendation.populationHealthProblem"),
              value: (
                <div className="py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3 roboto">
                  <Populations populations={questionPopulations} />
                </div>
              ),
            },
            ages
              ? {
                  label: t("recommendation.age"),
                  value: <Ages ages={ages} />,
                }
              : null,
            {
              label: t("recommendation.intervention"),
              value: (
                <div className="py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3 roboto">
                  <Interventions interventions={questionInterventions} />
                </div>
              ),
            },
            !_.isEmpty(emlLinks)
              ? {
                  label: t("labels.eml_links"),
                  value: <EmlLinks links={emlLinks} />,
                }
              : null,
            evidenceTableLink
              ? {
                  label: t("recommendation.evidence_table_link"),
                  value: (
                    <ExternalLink
                      url={evidenceTableLink}
                      label={t("recommendation.evidence_table_link")}
                    />
                  ),
                }
              : null,
            loveLink
              ? {
                  label: t("recommendation.love_link"),
                  value: <LoveLinks url={loveLink} label={t("recommendation.love_link")} />,
                }
              : null,
            showEquityLinks
              ? {
                  label: t("recommendation.equity_links"),
                  tooltip: t("tooltips.equity_links"),
                  value: <EquityLinks url={loveLink} progress={progress} />,
                }
              : null,
          ]}
        />
        {!commonStrengthAndCertainty && (
          <div>
            <Box
              tooltip={t("tooltips.strength")}
              className="mb-1 w-48 text-center mr-2 flex-grow-0"
              contentClassName="font-semibold"
              label={t("recommendation.recommendation_strength")}
            >
              <div>{t(`recommendation.strength.${question.gradeStrength}`)}</div>
            </Box>
            <Box
              tooltip={t("tooltips.certainty_of_evidence")}
              className="w-48 mb-1 text-center flex-grow-0"
              label={t("recommendation.certainty_of_evidence")}
            >
              <Certainty value={question.gradeCertaintyOfEvidence} />
            </Box>
          </div>
        )}
      </div>
      {!_.isEmpty(additionalRows) && (
        <div className="flex flex-row mt-8">
          <CustomTable className="flex-grow" rows={additionalRows} />
        </div>
      )}
    </Card>
  );
};

QuestionData.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool,
  interventions: PropTypes.arrayOf(interventionShape),
  populations: PropTypes.arrayOf(populationShape),
  questions: PropTypes.arrayOf(questionShape),
  emlLinks: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

QuestionData.defaultProps = {
  commonStrengthAndCertainty: true,
  emlLinks: [],
  interventions: [],
  populations: [],
  questions: [],
};

export default QuestionData;
