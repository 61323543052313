import _ from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetApi } from "../../lib/api";
import { parseTable } from "../../lib/helpers";
import Box from "../common/Box";
import LargeSpinner from "../common/LargeSpinner";
import SimpleSearch from "../common/SimpleSearch";
import { UrlRow } from "../common/URLRow";

type TRow = {
  title: string;
  fullReference: string;
  pubDate: string;
  url: string;
  ccitcPp: string;
};

interface ITableRow {
  row: TRow;
}

const TableRow = ({ row }: ITableRow) => {
  const { t } = useTranslation();

  return (
    <div className="mb-5 text-xs">
      <h1 className="roboto font-semibold text-base mb-2">{row.title}</h1>
      <div className="flex flex-col md:flex-row">
        <label className="font-semibold mr-2 flex-shrink-0">{t("labels.category")}:</label>
        <div className="break-all">{row.ccitcPp}</div>
      </div>
      <div className="flex flex-col md:flex-row">
        <label className="font-semibold mr-2 flex-shrink-0">{t("labels.fullReference")}:</label>
        <div className="break-all">{row.fullReference}</div>
      </div>
      <div className="flex flex-col md:flex-row">
        <label className="font-semibold mr-2 flex-shrink-0">{t("labels.pubDate")}:</label>
        <div className="break-all">{row.pubDate}</div>
      </div>
      {!_.isEmpty(row.url.trim()) && <UrlRow url={row.url} label={t("labels.url")} />}
      <div className="gradient-separator" />
    </div>
  );
};

const GuildelinesOnChangeOfCareTable = ({ table }: { table: string }) => {
  const parsedTable = useMemo(() => parseTable(table), [table]);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredRows = _.filter(parsedTable, (row: TRow) => {
    try {
      const sq = searchQuery.toLowerCase();

      return (
        row.ccitcPp.toLowerCase().indexOf(sq) !== -1 ||
        row.title.toLowerCase().indexOf(sq) !== -1 ||
        row.url.toLowerCase().indexOf(sq) !== -1 ||
        row.pubDate.toLowerCase().indexOf(sq) !== -1 ||
        row.fullReference.toLowerCase().indexOf(sq) !== -1
      );
    } catch (err) {
      // Many times when converting from google sheet to KB we have an error with missing column
      // in any row (cells are merged)
      // due to the size of the table it is hard to find,  so whenever such error appears we'll throw
      // it into console with full row to investigate
      console.error({ row, err });
    }
  });

  return (
    <div>
      <div className="mb-12 additional-guidance-search-container">
        <SimpleSearch searchQuery={searchQuery} onSearch={setSearchQuery} />
      </div>
      {_.isEmpty(filteredRows) ? (
        <div className="no-results" />
      ) : (
        _.map(filteredRows, (row: TRow, index: number) => <TableRow row={row} key={index} />)
      )}
    </div>
  );
};

const GuidelinesOnChangeOfCare = () => {
  const { t } = useTranslation();
  // @ts-ignore
  const [{ data, isLoading }] = useGetApi(`/additionalGuidance`);

  return (
    <div className="pb-6">
      <div className="w-full max-w-screen-lg relative mx-auto pb-6">
        <div className="w-full flex flex-row align-center justify-between mb-2">
          <h1 className={`pl-6 pt-2 text-2xl text-red-200 font-normal`}>
            {t("labels.guidelinesOnChangeOfCare")}
          </h1>
        </div>
        <Box className="p-5 bg-white mt-5">
          <div className="p-4">
            {isLoading ? (
              <LargeSpinner />
            ) : (
              // @ts-ignore
              <GuildelinesOnChangeOfCareTable table={_.first(data)?.body} />
            )}
          </div>
        </Box>
      </div>
    </div>
  );
};

GuidelinesOnChangeOfCare.propTypes = {};

export default GuidelinesOnChangeOfCare;
