import PropTypes from "prop-types";
import React, { ReactNode, useCallback, useState } from "react";
import Modal from "./Modal";

interface IInstructionModal {
  triggerLabel: ReactNode;
  children: ReactNode;
}

const InstructionModal = ({ children, triggerLabel }: IInstructionModal) => {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  return (
    <>
      <button className="hover:opacity-50" onClick={openModal}>
        {triggerLabel}
      </button>
      <Modal modalSize="auto" isOpen={modalOpen} onClose={closeModal} closeButton={true}>
        <div className="instruction-modal-content">{children}</div>
      </Modal>
    </>
  );
};

InstructionModal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InstructionModal;
