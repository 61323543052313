import React from "react";

interface ILoveLink {
  label: string;
  url: string;
}

const LoveLinks = ({ label, url }: ILoveLink) => {
  return (
    <div className="gradient-links-bg py-1 px-2 sm:py-3 sm:px-3 h-full">
      <div>
        <a className="flex flex-row" href={url} target="_blank" rel="noopener noreferrer">
          <span className="block flex-grow roboto">{label}</span>
          <span className="external-logo-link love-link" />
        </a>
      </div>
    </div>
  );
};

export default LoveLinks;
