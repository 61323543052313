import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AsyncTypeahead, TypeaheadMenu } from "react-bootstrap-typeahead";
import Typeahead from "react-bootstrap-typeahead/types/core/Typeahead";
import { Option } from "react-bootstrap-typeahead/types/types";
import { useTranslation } from "react-i18next";
import { handleApiError } from "../../lib/api";
import { axiosInstance as axios } from "../../lib/api_utils";
import { useDebounceValue } from "../../lib/custom_hooks";
import SearchIcon from "../../assets/search_icon.svg"
interface ISearch {
  onSearch: (s: string) => void;
  searchQuery?: string;
  className?: string;
  inputClassName?: string;
}

const Search = ({ onSearch, searchQuery, className, inputClassName }: ISearch) => {
  const { t, i18n } = useTranslation();
  const [query, setSearchQuery] = useState(searchQuery || "");

  const typeaheadRef = useRef<Typeahead>(null);

  const clearSearch = useCallback(() => {
    setSearchQuery("");
    onSearch("");
    if (typeaheadRef.current) {
      typeaheadRef.current.clear();
    }
  }, [onSearch]);

  const selectAndSubmit = useCallback(
    (suggestions: Option[]) => {
      if (_.first(suggestions)) {
        onSearch(_.first(suggestions) as string);
      }
    },
    [onSearch]
  );

  const onKeyDown = useCallback(
    (evt) => {
      if (evt.keyCode === 13) onSearch(query);
      if (evt.keyCode === 27) setSearchQuery("");
    },
    [query, onSearch]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<string[]>([]);

  const handleSearch = useCallback((query: string) => {
    setSearchQuery(query);
  }, []);

  const debouncedQuery = useDebounceValue(query);

  useEffect(() => {
    const abortController = new AbortController();
    setIsLoading(true);
    if (_.isEmpty(debouncedQuery)) return;
    axios
      .post(
        `/recommendations/_suggest?lng=${i18n.language}`,
        {
          searchString: debouncedQuery,
        },
        { signal: abortController.signal }
      )
      .then(({ data }) => {
        setOptions(data.suggestions);
      })
      .catch((err) => {
        if (err.code !== "ERR_CANCELED") {
          handleApiError(err);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {
      abortController.abort();
    };
  }, [debouncedQuery, i18n.language]);

  return (
    <div className={`relative ${className}`}>
      <div className="async-typehead">
        <AsyncTypeahead
          ref={typeaheadRef}
          defaultSelected={!_.isEmpty(query) ? [query] : undefined}
          emptyLabel={null}
          filterBy={_.stubTrue}
          id="search-recs"
          inputProps={{ className: `suggestion-input ${inputClassName ? inputClassName : ""}` }}
          isLoading={isLoading}
          labelKey="value"
          onChange={selectAndSubmit}
          onKeyDown={onKeyDown}
          onSearch={handleSearch}
          options={options}
          placeholder={t("actions.search_placeholder")}
          // This is kind of hacking, suggested by guys from react-typeahead library
          // https://github.com/ericgio/react-bootstrap-typeahead/issues/777
          // @ts-ignore
          renderMenu={(results, menuProps, props) => {
            return results.length ? (
              // @ts-ignore
              <TypeaheadMenu
                {...menuProps}
                labelKey={props.labelKey}
                options={results}
                text={props.text}
              />
            ) : null;
          }}
          renderMenuItemChildren={(option: Option) => <span>{option}</span>}
          useCache={false}
        />
        <button className="btn-search" onClick={() => onSearch(query)}>
          <svg className="w-full h-full">
            <use xlinkHref={`${SearchIcon}#search`} />
          </svg>
        </button>
        {!_.isEmpty(query) && <button className="btn-clear-search" onClick={clearSearch} />}
      </div>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
  inputClassName: PropTypes.string,
};

Search.defaultProps = {
  searchQuery: "",
  className: "",
};

export default Search;
