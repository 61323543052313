import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import ExpandableCard from "../common/ExpandableCard";
import ExternalLink from "../common/ExternalLink";

type Relations = {
    link: string;
    text: string;
  };

export interface RelatedRecommendationsProps {
  relatedRecommendations: Relations[];
  toggleRef: HTMLDivElement;
}

export default function RelatedRecommendations({
  relatedRecommendations,
  toggleRef,
}: RelatedRecommendationsProps) {
  const { t } = useTranslation();
  return (
    <ExpandableCard
      title={t("labels.relatedRecommendations")}
      contentClassName="px-1"
      toggleRef={toggleRef}
    >
      <div className="mx-4">
        {_.map(relatedRecommendations, ({ text, link }, idx) => (
          <div key={idx}>
            <ExternalLink className="text-red-200 hover:underline" url={link}>
              {_.isEmpty(text) ? `${t("labels.goToRelatedRecommendations")}` : text}
            </ExternalLink>
          </div>
        ))}
      </div>
    </ExpandableCard>
  );
}
